import React, { createContext, useContext, useState } from 'react';
import { transformDataToOptionsSelectForm } from 'src/components/Basicos/funcoes';
import { axiosApi } from 'src/services/axiosInstance';

const ControlUsuariosContext = createContext({});

interface IControlContextUsuariosProps {
    children: React.ReactNode
}

interface UsuariosProps {
    id: number,
    nome: string,
    sobrenome: string,
    email: string,
    telefone: number,
    facebook_id: number | null,
    google_id: number | null,
    status: any,
    arquivo_id: number | null,
    informacao_basica_id: number | null,
    endereco_id: number,
    deleted_at: number | string | null | Date,
    created_at: number | string | null | Date,
    updated_at: number | string | null | Date,
    device_token: number | string | null,
    id_facebook_aux: number | null,
    id_google_aux: number | null,
    nivel_acesso_id: number | null,
    grupo_id: number | null,
    arquivo: any,
    sedes: UsuarioSedeProps
}

interface UsuarioSedeProps {
    id: number,
    sede_id: number,
    usuario_id: number
    sede: SedeProps
}

interface SedeProps {
    id: number,
    nome: string
}

const UsuariosProvider: React.FC<IControlContextUsuariosProps> = React.memo(({ children }) => {
    const [usuarios, setUsuarios] = useState<UsuariosProps>();
    const [usuario, setUsuario] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);
    const [tiposSedesUsuario, setTipoSedesUsuario] = useState<any>();
    const [tiposGrupos, setTiposGrupos] = useState<any>();
    const [sedeActiveUsuario, setSedeActiveUsuario] = useState(0);
    const [grupoActive, setGrupoActive] = useState(0);
    const [sedesUsuario, setSedesUsuario] = useState<any>([]);
    const [tiposCargos, setTiposCargos] = useState<any>();
    const usuarioId = window.location.href.split("?")[1];

    const getUsuarios = async () => {
        try {
            setLoader(true);
            // var nivelAcesso = window.location.href.split("/")[4] == 'colaboradores' ? 1 : 2;
            const { data } = await axiosApi.get(window.location.href.split("/")[4]);
            setUsuarios(data.data)
            setSedesOptions();
            setGruposOptions();
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const getUsuario = async () => {
        setLoader(true);
        var id = usuarioId == undefined ? window.location.href.split("?")[1] : usuarioId
        if (id != undefined) {
            const { data } = await axiosApi.get(`usuario/${id}`);
            setUsuario({ ...data.data, status: 1 });
            setSedesUsuario(data.data.sedes)
            setGrupoActive(data.data.grupo_id)
        } else setUsuario({ ...usuario, nivel_acesso_id: 1, status: 1 })
        setSedesOptions();
        setGruposOptions();
        setLoader(false);
    }

    const setSedeUsuarioActive = (sedeId: Number): void => {
        setSedeActiveUsuario(Number(sedeId));
    }

    const setGrupo = (grupoId: number): void => {
        setGrupoActive(grupoId)
    }

    const setSedesOptions = async () => {
        const { data } = await axiosApi.get(`sede-responsavel`);
        var opcaoDefault = { id: 0, nome: "Selecione" }
        const opcaoDefault2 = [opcaoDefault].concat(data.data)
        setTipoSedesUsuario(transformDataToOptionsSelectForm(opcaoDefault2))
    }

    const setGruposOptions = async () => {
        const { data } = await axiosApi.get('grupos');
        var opcaoDefault = { id: 0, nome: "Selecione" }
        const opcaoDefault2 = [opcaoDefault].concat(data.data);
        const response = await axiosApi.get('cargos');
        const opcaoDefault3 = [opcaoDefault].concat(response.data.data);
        setTiposGrupos(transformDataToOptionsSelectForm(opcaoDefault2));
        setTiposCargos(transformDataToOptionsSelectForm(opcaoDefault3));
    }

    const filterSede = (dataTable: any) => {
        if (sedeActiveUsuario !== 0)
            return dataTable?.filter((item: any) => { return item?.sedes.some((sede: any) => sede?.id == sedeActiveUsuario) });
        return dataTable
    }

    const filterStatusUser = (dataTable: any) => {
        return dataTable?.filter((item: any) => { return item?.status == 1 });
    }

    const filterGrupo = (dataTable: any) => {
        if (grupoActive !== 0)
            return dataTable?.filter((item: any) => { return item?.grupo_id === grupoActive });
        return dataTable
    }

    const getValueCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(sedesUsuario)
        if (event.target.checked == true) {
            sedesUsuario?.push({
                sede_id: event.target.id,
                usuario_id: usuarioId
            })
        } else {
            var index = sedesUsuario.findIndex((element: any) => element.id == event.target.id)
            sedesUsuario.splice(index, 1);
        }
        setSedesUsuario(() => setSedesUsuario(sedesUsuario))
    }

    const saveCliente = async (history: any) => {
        try {
            setLoader(true);
            await axiosApi.put(`cliente/${usuario.id}`, usuario)
            history.push(`/auxiliares/clientes`)
            setLoader(false)
        } catch (error) {
            console.log(error);
        }
    }

    const saveUsuario = async (history: any) => {
        try {
            setLoader(true);
            var id = usuarioId == undefined ? window.location.href.split("?")[1] : usuarioId
            var request = id == undefined ? "POST" : "PUT";
            var url = id == undefined ? "usuario-sede" : `usuario-sede/${id}`;
            var urlUsuario = id == undefined ? "/colaborador/create" : `colaborador/${id}`;
            const response =
                await axiosApi(
                    {
                        method: request,
                        url: urlUsuario,
                        data: usuario
                    }
                );
            var post = undefined
            if (request == "POST" && sedesUsuario?.length != 0) {
                post = {
                    usuario_id: response.data.data.id,
                    loja: sedesUsuario
                }
            }
            if (sedesUsuario?.length != 0) {
                await axiosApi(
                    {
                        method: request,
                        url: url,
                        data: post != undefined ? post : sedesUsuario
                    }
                );
                setSedesUsuario(undefined)
            }
        } catch (error) {
            console.log(error);
        } finally {
            setGrupoActive(0)
            setUsuario(undefined)
            history.push(`/auxiliares/colaboradores`)
            setLoader(false)
        }
    }

    const onChangeSlide = (event: any) => {
        const { checked } = event.target;
        var teste = { ...usuario, status: checked == true ? 1 : 0 }
        setUsuario(teste)
    }

    const onChangeEventSelect = (name: string, value: number) => {
        var teste = { ...usuario, [name]: value }
        setUsuario(teste)
    }

    return (
        <ControlUsuariosContext.Provider value={{
            usuarios,
            usuario,
            loader,
            tiposSedesUsuario,
            tiposGrupos,
            grupoActive,
            sedeActiveUsuario,
            sedesUsuario,
            tiposCargos,
            setUsuarios,
            getUsuarios,
            setSedeUsuarioActive,
            filterSede,
            filterGrupo,
            setGrupo,
            getUsuario,
            getValueCheckBox,
            onChangeSlide,
            onChangeEventSelect,
            saveUsuario,
            setUsuario,
            filterStatusUser,
            saveCliente
        }}>
            {children}
        </ControlUsuariosContext.Provider>
    );
});

function useControlContextUsuarios(): any {
    const context = useContext(ControlUsuariosContext);
    if (!context) {
        throw new Error("useControll must a be used with UsuariosProvider");
    }
    return context;
}

export { UsuariosProvider, useControlContextUsuarios };
